import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import {
  filterMarkets,
  getCanonicalUrl,
  getHrefLanguages,
} from '@hultafors/shared/helpers';

import { datoApi, markets } from '@hultafors/hultafors/api';
import {
  GlobalFields,
  LandingPageFragment,
  PageProps,
} from '@hultafors/hultafors/types';

import { prodDomains } from '../next.domains';

const ContentArea = dynamic(() =>
  import('@hultafors/hultafors/components').then((mod) => mod.ContentArea),
);
const Page = dynamic(() =>
  import('@hultafors/hultafors/components').then((mod) => mod.Page),
);

export interface LandingPageProps extends PageProps {
  content?: LandingPageFragment;
}

export const LandingPage: NextPage<LandingPageProps> = ({
  canonical,
  content,
  hrefLanguages,
}) => {
  return (
    <Page
      seo={content.seo}
      canonical={canonical}
      hrefLanguages={hrefLanguages}
      testId="landing-page"
    >
      <ContentArea content={content.contentArea} />
    </Page>
  );
};

export const getStaticProps: GetStaticProps<LandingPageProps> = async ({
  locale,
}) => {
  const revalidate = 60; // 1 minute
  try {
    const dato = datoApi(locale);

    const canonical = getCanonicalUrl(locale, prodDomains, 'www.hultafors.com');
    const {
      data: { landingPage, ...globalFields },
    } = await dato.getLandingPage();

    const hrefLanguages = getHrefLanguages(
      null,
      filterMarkets(markets, 'HULTAFORS'),
      landingPage?.pageLocales,
    );

    return {
      props: {
        content: landingPage || null,
        ...(globalFields as GlobalFields),
        canonical,
        hrefLanguages,
      },
      revalidate,
    };
  } catch (error) {
    console.error(error);
    return { notFound: true, revalidate };
  }
};

export default LandingPage;
